import React from "react";
import Home from "./Home/Home";

function CourtRoom() {
  return (
    <>
      <Home />
    </>
  );
}

export default CourtRoom;
